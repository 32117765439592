import React from "react";

const ParsingButton = () => {
    return (
        <div className="pt-[50px] bg-[#02070D]">
            <a href="/parsing" className="py-5 px-8 flex flex-wrap w-fit mx-auto items-center parsingButton hover:before:!text-[#DCB045] hover:duration-500">
                <div className="flex items-center">
                    <span className="block text-black text-[27px] leading-[30px] h-[30px] mr-3">
                        ✨
                    </span>
                    <span className="block text-[#FBFBFC] text-[16px] leading-[34px] font-medium relative top-[1px]">
                        Эй, пссст, у нас еще заказывают парсинг любых сайтов!
                    </span>
                </div>
                <div className="hidden sm:block h-[34px] w-[2px] mx-6"
                     style={{background: 'rgba(255, 255, 255, 0.07)'}}/>
                <div className="flex items-center mx-auto">
                    <span className="block text-[#DCB045] text-[16px] leading-[34px] font-medium mr-2 relative top-[1px]">
                        Посмотрите и заказывайте
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10"
                         fill="none">
                        <path d="M1.82617 1.48047L5.3457 5.0004L1.82617 8.51994" stroke="#DCB045"
                              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </a>
        </div>
    )
}

export {ParsingButton};
